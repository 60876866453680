"use client";

import React, { useEffect, useState } from "react";
import ListingPreview from "./ListingPreview";
import Pagination from "@/app/components/Pagination";
import { SafeListing, SafeUser } from "@/app/types";
import ViewAllJobListingsButton from "../ButtonAllListings";
import ListingCardJobPreview from "./ListingCardJobPreview";
import { useRouter, useSearchParams } from "next/navigation";
import { ExchangeCurrency } from "@prisma/client";

interface ListingPreviewSectionProps {
  listings: SafeListing[];
  currentUser?: SafeUser | null;
  totalPages: number;
  currentPage: number;
}

const ListingPreviewSection: React.FC<ListingPreviewSectionProps> = ({
  listings,
  currentUser,
  totalPages,
  currentPage,
}) => {
  const [selectedListing, setSelectedListing] = useState<SafeListing | null>(
    null,
  );
  const [localPage, setLocalPage] = useState(currentPage);
  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (listings.length > 0) {
      setSelectedListing(listings[0]);
    }
  }, [listings]);

  useEffect(() => {
    setLocalPage(currentPage);
  }, [currentPage]);

  const handleListingClick = (listing: SafeListing) => {
    setSelectedListing(listing);
  };

  const handlePageChange = (page: number) => {
    setLocalPage(page);
    const params = new URLSearchParams(searchParams?.toString());
    params.set("listingPage", page.toString());
    router.push(`/?${params.toString()}`);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          {listings.map((listing) => (
            <div key={listing.id} onClick={() => handleListingClick(listing)}>
              <ListingCardJobPreview
                key={listing.id}
                listing={listing}
                currentUser={currentUser}
                onClick={handleListingClick}
                isPreviewActive={!!selectedListing}
              />
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/2 pl-4 hidden md:block">
          {selectedListing ? (
            <ListingPreview
              listing={selectedListing}
              reviews={selectedListing.diveCenter.reviews}
            />
          ) : (
            <div>No listing selected</div>
          )}
        </div>
      </div>

      <div className="mt-10 text-center">
        <ViewAllJobListingsButton currentUser={currentUser} />
      </div>

      {totalPages > 1 && (
        <Pagination
          currentPage={localPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default ListingPreviewSection;

"use client";

import React from "react";
import { SafeListing, SafeUser } from "@/app/types";
import { formatDistanceToNow } from "date-fns";
import RatingStar from "../RatingStar";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaClock } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import BookmarkButton from "../BookmarkButton";
import Image from "next/image";

interface ListingCardJobPreviewProps {
  listing: SafeListing;
  onClick: (listing: SafeListing) => void;
  isPreviewActive?: boolean;
  currentUser?: SafeUser | null;
}

const ListingCardJobPreview: React.FC<ListingCardJobPreviewProps> = ({
  listing,
  onClick,
  isPreviewActive,
  currentUser,
}) => {
  const daysAgo = listing.createdAt
    ? formatDistanceToNow(new Date(listing.createdAt), { addSuffix: true })
    : "N/A";

  const listingData = {
    title: listing?.title,
    position: listing?.position,
    type: listing?.type,
    contact: listing?.contact,
    description: listing?.description,
  };
  const urlWithParams = `/listings/${listing?.id}?listingData=${encodeURIComponent(
    JSON.stringify(listingData),
  )}`;

  const handleClick = () => {
    if (!isPreviewActive) {
      onClick(listing);
    }
  };

  return (
    // <a href={urlWithParams} rel="noopener noreferrer" className="col-span-1 cursor-pointer group">
    <div onClick={handleClick} className="col-span-1 cursor-pointer group">
      <div className="flex flex-col justify-between m-4 py-4 sm:px-6 px-4 border rounded-md shadow-md">
        <div className="flex flex-row w-full justify-between items-center">
          <a
            href={urlWithParams}
            rel="noopener noreferrer"
            className="col-span-1 cursor-pointer group"
          >
            <h2 className="md:text-lg text-md font-semibold capitalize">
              {listing.title}
            </h2>
          </a>
          {!currentUser || (currentUser && currentUser.userType === "Diver") ? (
            <div className="text-right">
              <BookmarkButton
                listingId={listing.id}
                currentUser={currentUser}
              />
            </div>
          ) : null}
        </div>
        <div className="flex flex-col md:flex-row items-start">
          <div className="flex-grow ">
            <div className="flex w-full flex-row sm:flex-col sm:items-start justify-between sm:justify-start">
              <div className="text-sm text-gray-600 flex-1 sm:text-left text-right md:mr-0 mr-12">
                {listing.position}
              </div>
              <div className="text-sm font-semibold text-atoll-600 sm:text-left text-right">
                {listing.type}
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-sm text-black sm:mt-2 mt-0">
                {listing.diveCenter.agency}
              </p>
              <div className="flex flex-row items-center sm:text-sm text-xs mt-1">
                <GrLocation size={14} className="text-neutral-500 mr-1" />
                <p className="text-sm text-gray-500">
                  {listing.diveCenter.city}, {listing.diveCenter.country}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center md:items-end space-x-2 md:ml-2 ml-0">
            <div className="text-center md:text-right mt-2 md:mt-4">
              <div className="flex items-center space-x-2">
                <Image
                  src={listing.diveCenter.photo || "/images/logo-scuba-big.png"}
                  alt={`${listing.diveCenter.user.name} logo`}
                  width={20}
                  height={20}
                  className="rounded-full object-cover"
                  loading="lazy"
                />
                <div className="flex md:flex-col flex-row">
                  <p className="text-sm font-medium capitalize text-left md:mr-0 mr-2">
                    {listing.diveCenter.user.name}
                  </p>
                  <RatingStar
                    averageRating={listing.diveCenter.averageRating || 0}
                    reviewsNumber={listing.diveCenter.totalReviews || 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-xs text-gray-500 text-right mt-2 sm:mt-0">
          Posted {daysAgo}
        </p>
      </div>
    </div>
    // </a>
  );
};

export default ListingCardJobPreview;

"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";
import useLoginModal from "@/app/hooks/useLoginModal";
import { SafeUser, SimplifiedUser } from "@/app/types";
import toast from "react-hot-toast";

interface ViewAllJobListingsButtonProps {
  currentUser?: SafeUser | null;
  // currentUser?: SimplifiedUser | null;
}

const ViewAllJobListingsButton: React.FC<ViewAllJobListingsButtonProps> = ({
  currentUser,
}) => {
  const loginModal = useLoginModal();
  const router = useRouter();

  const onViewAll = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (!currentUser) {
        loginModal.onOpen();
      } else if (currentUser.userType === "Center") {
        toast.error("Only dive pros can access this view.");
      } else if (currentUser && currentUser.userType === "Diver") {
        router.push("/job-listings");
      }
    },
    [currentUser, loginModal, router],
  );

  // if (currentUser?.userType !== "Diver") {
  //     return null;
  // }

  return (
    <a
      href="/job-listings"
      rel="noopener noreferrer"
      className="mt-2 inline-block px-4 py-2 bg-atoll-600 text-white rounded-md hover:bg-atoll-500 cursor-pointer"
      onClick={onViewAll}
    >
      View all job listings
    </a>
  );
};

export default ViewAllJobListingsButton;

"use client";

import useCountries from "@/app/hooks/useCountries";
import { SafeListing, SafeUser } from "@/app/types";

import Heading from "../Heading";
import ShareModal from "../modals/ShareModal";
import { FiShare } from "react-icons/fi";
import useShareModal from "@/app/hooks/useShareModal";
import BookmarkButton from "../BookmarkButton";
import { useCallback } from "react";

interface ListingHeadProps {
  title: string;
  locationValue: string;
  listing: SafeListing;
  currentUser?: SafeUser | null;
}

const ListingHead: React.FC<ListingHeadProps> = ({
  title,
  locationValue,
  currentUser,
  listing,
}) => {
  const { getByName } = useCountries();

  const location = getByName(locationValue);
  const shareModal = useShareModal();

  const openShareModal = useCallback(() => {
    shareModal.onOpen();
  }, [shareModal]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Heading
          title={title}
          subtitle={`${location?.label}, ${location?.region}`}
        />
        <div className="flex flex-col items-end ml-auto">
          {!currentUser || (currentUser && currentUser.userType === "Diver") ? (
            <div className="text-right mb-2">
              <BookmarkButton
                listingId={listing.id}
                currentUser={currentUser}
              />
            </div>
          ) : null}
          <button
            onClick={openShareModal}
            className="flex items-center text-neutral-700 text-sm font-bold hover:underline focus:underline"
          >
            <FiShare size={18} className="text-neutral-700 mr-2" />
            Share
          </button>
          <ShareModal title={title} url={window.location.href} />
        </div>
      </div>
    </>
  );
};

export default ListingHead;

"use client";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import useFavorite from "@/app/hooks/useFavorite";
import { SafeUser } from "@/app/types";

import ClientOnly from "./ClientOnly";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";

interface BookmarkButtonProps {
  listingId: string;
  currentUser?: SafeUser | null;
}

const BookmarkButton: React.FC<BookmarkButtonProps> = ({
  listingId,
  currentUser,
}) => {
  const { hasFavorited, toggleFavorite } = useFavorite({
    listingId,
    currentUser,
  });

  return (
    <div
      onClick={toggleFavorite}
      className="
        relative
        hover:opacity-80
        transition
        cursor-pointer
        w-5 h-5
        flex
        items-center
        justify-center
      "
    >
      {hasFavorited ? (
        <FaBookmark size={18} className="fill-atoll-700" />
      ) : (
        <FaRegBookmark size={18} className="fill-atoll-700" />
      )}
    </div>
  );
};

export default BookmarkButton;

import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const maxPageButtons = 4;
  const pageNumbers = [];

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  if (totalPages > maxPageButtons && startPage > 1) {
    pageNumbers.push(
      <span
        key={1}
        className={`cursor-pointer ${currentPage === 1 ? "text-atoll-700" : "text-neutral-600"} mx-2`}
        onClick={() => onPageChange(1)}
      >
        1
      </span>,
    );

    if (startPage > 1) {
      pageNumbers.push(<span key="ellipsis1">...</span>);
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(
      <span
        key={i}
        className={`cursor-pointer ${currentPage === i ? "text-atoll-700" : "text-neutral-600"} mx-2`}
        onClick={() => onPageChange(i)}
      >
        {i}
      </span>,
    );
  }

  if (totalPages > maxPageButtons && endPage < totalPages - 1) {
    if (endPage < totalPages - 1) {
      pageNumbers.push(<span key="ellipsis2">...</span>);
    }

    pageNumbers.push(
      <span
        key={totalPages}
        className={`cursor-pointer ${currentPage === totalPages ? "text-atoll-700" : "text-neutral-600"} mx-2`}
        onClick={() => onPageChange(totalPages)}
      >
        {totalPages}
      </span>,
    );
  }

  return (
    <div className="flex justify-center mt-6">
      <span
        className={`cursor-pointer ${currentPage === 1 ? "text-neutral-300" : "text-atoll-700"} mx-2`}
        onClick={() => onPageChange(currentPage - 1)}
      >
        &laquo;
      </span>
      {pageNumbers}
      <span
        className={`cursor-pointer ${currentPage === totalPages ? "text-neutral-300" : "text-atoll-700"} mx-2`}
        onClick={() => onPageChange(currentPage + 1)}
      >
        &raquo;
      </span>
    </div>
  );
};

export default Pagination;

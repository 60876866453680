"use client";

import { useRouter } from "next/navigation";

interface ClearSearchButtonProps {
  searchQuery?: string;
  cityOrCountry?: string;
  jobType?: string;
  agencyQuery?: string;
}

const ClearSearchButton: React.FC<ClearSearchButtonProps> = ({
  searchQuery,
  cityOrCountry,
  jobType,
  agencyQuery,
}) => {
  const router = useRouter();

  const clearSearch = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("searchQuery");
    url.searchParams.delete("cityOrCountry");
    url.searchParams.delete("jobType");
    url.searchParams.delete("agencyQuery");
    window.history.replaceState({}, "", url.toString());
    router.push("/");
  };

  if (!searchQuery && !cityOrCountry) return null;

  return (
    <button
      onClick={clearSearch}
      className="px-2 py-1 sm:px-4 sm:py-2 bg-atoll-600 text-white rounded-md hover:bg-atoll-500 cursor-pointer text-sm sm:text-base"
    >
      Clear Search
    </button>
  );
};

export default ClearSearchButton;

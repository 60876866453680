"use client";

import React, { useEffect, useState } from "react";
import CenterCard from "./CenterCard";
import Pagination from "@/app/components/Pagination";
import { SafeDiveCenterUserReviews, SafeUser } from "@/app/types";
import { useRouter, useSearchParams } from "next/navigation";
import { ExchangeCurrency } from "@prisma/client";

interface CenterListProps {
  filteredCenters: SafeDiveCenterUserReviews[];
  currentUser?: SafeUser | null;
  rates: ExchangeCurrency | null;
  totalPages: number;
  currentPage: number;
}

const CenterList: React.FC<CenterListProps> = ({
  filteredCenters,
  currentUser,
  rates,
  totalPages,
  currentPage,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [localPage, setLocalPage] = useState(currentPage);

  useEffect(() => {
    setLocalPage(currentPage);
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setLocalPage(page);

    const params = new URLSearchParams(searchParams?.toString());
    params.set("page", page.toString());
    router.push(`/?${params.toString()}`);
  };

  return (
    <div>
      {filteredCenters.map((center) => (
        <CenterCard
          key={center.id}
          center={center}
          currentUser={currentUser}
          rates={rates}
        />
      ))}

      {totalPages > 1 && (
        <Pagination
          currentPage={localPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default CenterList;

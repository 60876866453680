"use client";

import React, { useEffect, useState } from "react";
import CenterCard from "./../centers/CenterCard";
import { SafeDiveCenterUserReviews, SafeUser } from "@/app/types";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { ExchangeCurrency } from "@prisma/client";

interface TopDiveCentersCarouselProps {
  centers: SafeDiveCenterUserReviews[];
  currentUser?: SafeUser | null;
  rates: ExchangeCurrency | null;
}

const TopDiveCentersCarousel: React.FC<TopDiveCentersCarouselProps> = ({
  centers,
  currentUser,
  rates,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + centers.length) % centers.length,
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % centers.length);
  };

  useEffect(() => {
    const intervalId = setInterval(handleNextClick, 8000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="relative w-full max-w-3xl mx-auto px-2 sm:px-12">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {centers.map((center) => (
            <div key={center.id} className="w-full flex-shrink-0">
              <CenterCard
                center={center}
                currentUser={currentUser}
                rates={rates}
              />
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={handlePrevClick}
        className="absolute left-0 sm:left-2 top-1/2 transform -translate-y-1/2 z-10 p-2"
        aria-label="Previous slide"
      >
        <IoIosArrowDropleftCircle size={24} />
      </button>
      <button
        onClick={handleNextClick}
        className="absolute right-0 sm:right-2 top-1/2 transform -translate-y-1/2 z-10 p-2"
        aria-label="Next slide"
      >
        <IoIosArrowDroprightCircle size={24} />
      </button>
    </div>
  );
};

export default TopDiveCentersCarousel;

"use client";

import { SafeListing, SafeReviews, SafeUser } from "@/app/types";
import Container from "@/app/components/Container";
import ListingHead from "@/app/components/listings/ListingHead";
import ListingInfo from "@/app/components/listings/ListingInfo";
import ListingDetails from "@/app/components/listings/ListingDetails";
import { Review } from "@prisma/client";
import { GrLocation } from "react-icons/gr";
import { GiScubaTanks } from "react-icons/gi";
import { CgSandClock } from "react-icons/cg";
import { MdOutlineOtherHouses } from "react-icons/md";
import { format } from "date-fns";
import { useState } from "react";
import Rating from "../Rating";
import { getMostCommonCurrency } from "@/app/utils/getMostCommonCurrency";
import useCurrencies from "@/app/hooks/useCurrencies";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaClock } from "react-icons/fa";

interface ListingPreviewProps {
  listing: SafeListing;
  currentUser?: SafeUser | null;
  reviews?: Review[];
}

const ListingPreview: React.FC<ListingPreviewProps> = ({
  listing,
  currentUser,
  reviews,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const mostCommonCurrencyCode = getMostCommonCurrency(reviews);
  const { getByCode } = useCurrencies();
  const currencyOption = getByCode(mostCommonCurrencyCode || "USD");

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const description = listing.description || "-";
  const isLongDescription = description.length > 300;

  const handleGetDirections = () => {
    const latitude = listing.diveCenter?.latitude || 0;
    const longitude = listing.diveCenter?.longitude || 0;

    const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

    window.open(directionsUrl, "_blank");
  };

  const centerData = {
    name: listing.diveCenter.user.name,
    agency: listing.diveCenter.agency,
    city: listing.diveCenter.city,
    country: listing.diveCenter.country,
  };

  const urlWithParamsCenter = `/center/${listing.diveCenterId}?centerData=${encodeURIComponent(
    JSON.stringify(centerData),
  )}`;

  const listingData = {
    title: listing?.title,
    position: listing?.position,
    type: listing?.type,
    contact: listing?.contact,
    description: listing?.description,
  };
  const urlWithParams = `/listings/${listing?.id}?listingData=${encodeURIComponent(
    JSON.stringify(listingData),
  )}`;

  return (
    <Container>
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-col gap-2 mt-6">
          <a
            href={urlWithParams}
            rel="noopener noreferrer"
            className="col-span-1 cursor-pointer group"
          >
            <ListingHead
              currentUser={currentUser}
              listing={listing}
              title={listing.title}
              locationValue={listing.diveCenter.country || ""}
            />
          </a>
          <div className="col-span-4 flex flex-col gap-2">
            <div className="font-light text-neutral-500 gap-1">
              <div className="font-light text-neutral-600 gap-1 flex">
                <div className="flex flex-col items-center mr-4 ">
                  <div className="font-light text-justify pt-2 pb-1">
                    <GiScubaTanks size={19} />
                  </div>
                  <div className="font-light text-justify pt-2 pb-1">
                    <CgSandClock size={19} />
                  </div>
                  <div className="font-light text-justify pt-2 pb-1">
                    <MdOutlineOtherHouses size={19} />
                  </div>
                </div>
                <div className="flex flex-col items-center text-sm">
                  <div className="text-justify pt-2 pb-1 capitalize text-atoll-600 font-semibold">
                    {listing?.position || "To be determined"}
                  </div>
                  <div className="text-justify pt-2 pb-1 capitalize text-black text-md">
                    {listing?.type || "-"}
                  </div>
                  <div className="text-justify pt-2 pb-1 capitalize text-black text-md font-semibold">
                    {listing?.diveCenter.agency || "-"}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="leading-6 w-full text-neutral-500 text-sm text-justify">
              {isLongDescription && !isExpanded
                ? `${description.slice(0, 300)}...`
                : description}
            </div>
            {isLongDescription && (
              <button
                onClick={toggleExpansion}
                className="text-atoll-700 font-semibold mt-2 text-sm sm:text-md"
              >
                {isExpanded ? "Show Less" : "Show More"}
              </button>
            )}
            {/* <div className="leading-6 text-sm sm:text-md text-justify font-light text-neutral-500 pt-3">
                            {listing.description}
                        </div> */}
            <div className="mt-2">
              <span className="text-neutral-500">Contact: </span>
              {listing.contact}
            </div>
            <div className="font-light text-sm italic py-2 text-black">
              Created by: {format(new Date(listing.createdAt), "MMM dd, yyyy")}
            </div>
            <hr />
            <div className="flex flex-col gap-2 mt-1">
              <div className="text-md font-semibold flex flex-row items-center gap-2">
                <div>
                  Posted by{" "}
                  <span className="cursor-pointer hover:underline">
                    <a
                      href={`${urlWithParamsCenter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {listing.diveCenter.user?.name}
                    </a>
                  </span>
                </div>
              </div>
              <div>
                <Rating
                  averageRating={listing.diveCenter.averageRating || 0}
                  reviewsNumber={listing.diveCenter.totalReviews || 0}
                />
              </div>
              <div className="flex flex-row items-center text-left text-sm mt-2">
                <BiMoneyWithdraw size={14} className="mr-2" />
                {listing.diveCenter.averageSalary ? (
                  <>
                    {listing.diveCenter.averageSalary} {currencyOption?.symbol}
                    /month
                    <span className="hidden md:inline">
                      {" "}
                      &nbsp;(from reviews)
                    </span>
                  </>
                ) : (
                  " No data yet"
                )}
              </div>
              <div className="flex flex-row items-center text-sm text-left text-atoll-700 mt-1">
                <FaClock size={14} className="mr-2" />
                {listing.diveCenter.averageWorkingHours ? (
                  <>
                    {listing.diveCenter.averageWorkingHours} hrs/day
                    <span className="hidden md:inline">
                      {" "}
                      &nbsp;(from reviews)
                    </span>
                  </>
                ) : (
                  " No data yet"
                )}
              </div>
            </div>
            <hr />
            <div className="font-light text-neutral-500 gap-1 py-2">
              <div className="flex gap-2">
                <GrLocation size={18} className="text-neutral-700" />
                <div className="flex flex-col">
                  <p className="text-sm capitalize">
                    {listing.diveCenter?.address || ""},{" "}
                    {listing.diveCenter?.city || ""},{" "}
                    {listing.diveCenter?.postalCode || ""},{" "}
                    {listing.diveCenter?.country || ""}
                  </p>
                  <p
                    onClick={handleGetDirections}
                    className="font-bold text-sm text-cyan-700 cursor-pointer hover:underline mt-2"
                  >
                    Get directions
                  </p>
                </div>
              </div>
            </div>

            <hr />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ListingPreview;

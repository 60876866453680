"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";
import useLoginModal from "@/app/hooks/useLoginModal";
import { SafeUser } from "@/app/types";
import toast from "react-hot-toast";

interface RegisterDiveCenterButtonProps {
  currentUser?: SafeUser | null;
}

const RegisterDiveCenterButton: React.FC<RegisterDiveCenterButtonProps> = ({
  currentUser,
}) => {
  const loginModal = useLoginModal();
  const router = useRouter();

  const onRegister = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (!currentUser) {
        loginModal.onOpen();
      } else if (currentUser.userType === "Center") {
        toast.error("Centers cannot register other centers.");
      } else if (currentUser) {
        router.push("/register-dive-center");
      }
    },
    [currentUser, loginModal, router],
  );

  return (
    <a
      href="/register-dive-center"
      rel="noopener noreferrer"
      className="inline-block px-4 py-2 bg-atoll-600 text-white rounded-md hover:bg-atoll-500 cursor-pointer"
      onClick={onRegister}
    >
      Register a dive center now!
    </a>
  );
};

export default RegisterDiveCenterButton;

import React from "react";
import { FaStar, FaRegStar, FaStarHalfAlt, FaStarHalf } from "react-icons/fa";
import { IoIosStarOutline, IoIosStarHalf, IoIosStar } from "react-icons/io";

interface RatingProps {
  averageRating: number;
  reviewsNumber: number;
}

const Rating: React.FC<RatingProps> = ({ averageRating, reviewsNumber }) => (
  <div className="flex items-center mt-1">
    {reviewsNumber === 0 ? (
      <div className="flex items-center mr-2">
        {[...Array(5)].map((_, index) => (
          <span key={index}>
            <FaStar size={12} className="text-gray-300" />
          </span>
        ))}
        <div className="text-neutral-600 ml-2">No reviews yet</div>
      </div>
    ) : (
      <>
        <div className="flex items-center mr-2">
          {[...Array(5)].map((_, index) => {
            const isFull = index + 1 <= Math.floor(averageRating);
            const isHalf =
              index + 1 === Math.ceil(averageRating) && averageRating % 1 !== 0;
            return (
              <span key={index}>
                {isFull ? (
                  <FaStar size={12} className="text-yellow-500" />
                ) : isHalf ? (
                  <>
                    <FaStarHalfAlt
                      size={12}
                      key={index}
                      className="text-yellow-500"
                    />
                    {/* <FaStarHalf size={12} className="text-yellow-500" style={{ clipPath: "polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)", color: 'blue' }} /> */}
                  </>
                ) : (
                  <FaRegStar size={12} className="text-gray-300" />
                )}
                {/* {isFull ? (
                                    <IoIosStar key={index} size={18} className="text-yellow-500" />
                                ) : isHalf ? (
                                    <IoIosStarHalf key={index} size={18} className="text-yellow-500" />
                                ) : (
                                    <IoIosStarOutline key={index} size={18} className="text-gray-300" />
                                )} */}
              </span>
            );
          })}
        </div>
        <span className="text-md text-neutral-800">
          {averageRating.toFixed(1)}
        </span>
        <div className="font-light text-neutral-500 text-sm ml-3 underline hover:text-atoll-700">
          <a href="#reviews">{`${reviewsNumber} reviews`}</a>
        </div>
      </>
    )}
  </div>
);

export default Rating;

import(/* webpackMode: "eager" */ "/app/app/components/ButtonRegisterCenter.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/ButtonWriteReview.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/centers/CenterList.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/ClearSearchButton.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/ClientOnly.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/Container.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/home/DivingProfessionalsSection.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/home/RegisterDiveCenterSection.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/home/TopDiveCentersCarousel.tsx");
import(/* webpackMode: "eager" */ "/app/app/components/listings/ListingPreviewSection.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@13.5.7_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@13.5.7_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js")
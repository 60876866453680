"use client";

import React from "react";
import WriteReviewButton from "./../ButtonWriteReview";
import RegisterDiveCenterButton from "./../ButtonRegisterCenter";
import { SafeUser } from "@/app/types";

const DivingProfessionalsSection = ({
  currentUser,
}: {
  currentUser: SafeUser | null;
}) => {
  return (
    <div className="pt-14 px-10">
      <h2 className="text-2xl font-semibold mb-6">For Dive Professionals</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-medium">Your opinion matters!</h3>
          <p className="text-gray-600 mb-3 text-justify sm:text-left">
            Your feedback helps others make informed decisions.
          </p>
          <WriteReviewButton currentUser={currentUser} />
        </div>
        <div>
          <h3 className="text-lg font-medium">Register a Dive Center</h3>
          <p className="text-gray-600 text-justify sm:text-left mb-3">
            Can't you find a center? Register it now!
          </p>
          <RegisterDiveCenterButton currentUser={currentUser} />
        </div>
      </div>
    </div>
  );
};

export default DivingProfessionalsSection;
